import React from "react"

import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import SEO from "../components/seo"
import Cases from "../components/projects/Cases"

const ProjectsPage = () => (
  <InnerLayout>
    <SEO title="Projects" bodyClass="CasesPage"/>
    <InnerWrapper>
      <Cases/>
    </InnerWrapper>
  </InnerLayout>
)

export default ProjectsPage
