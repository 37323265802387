import React from "react"
import PropTypes from "prop-types"

import ProjectsData from '../../data/projects'
import CasePreview from "./CasePreview"

const Cases = (props) => {

  const allCases = ProjectsData();

  let theCases = props.number ? allCases.slice(0, props.number) : allCases;
  theCases = props.project ? [allCases[props.project]] : theCases;

  return (
    <section className="Cases outer-padding-x outer-padding-y">
      <div className="Cases__intro max-width">
        { props.number ? (
          <h2 className="fadein">Selected projects</h2>
        ) : (
          <h1 className="fadein">Selected projects</h1>
        ) }
        <p className="has-big-font-size fadein">A selection of websites, web applications and creative technology projects.</p>
      </div>
      {theCases.map((theCase) =>
        <CasePreview key={theCase.id} image={theCase.image} title={theCase.title} category={theCase.category} description={theCase.description} color={theCase.color} url={theCase.url} />
      )}
    </section>
  )
}

Cases.propTypes = {
  number: PropTypes.number,
}

export default Cases

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1440) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }
}
`;